import React from 'react';
import './App.scss';
import createRules from './create_rules.png';
import shareYourSquad from './share_your_squad.png';
import shareYourChallenge from './share_your_challenge.png';
import fixErrors from './fix_errors.png';
import logo from './logo.jpg';


import { Button, Divider } from "@blueprintjs/core";
import {BrowserRouter, Route, Switch, Link, useLocation} from "react-router-dom";
import {Spinner, SpinnerSize} from "@blueprintjs/core"; 

import Carousel, {Dots} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { Challenge } from './model';


const SolutionEditor = React.lazy(() => import('./SolutionEditor'));
const SolutionDetail = React.lazy(() => import('./SolutionDetail'));
const ChallengeEditor = React.lazy(() => import('./ChallengeEditor'));
const DisabledChallengeEditor = React.lazy(() => import('./DisabledChallengeEditor'));
const ShareButtons = React.lazy(() => import('./ShareButtons'));





function HomePageNavbar() {
  return (
    <div className="home-page-navbar">
      <Link to="/">
        <img src={logo} className="logo"
          alt="Logo that depicts a soccer ball on a blackboard covered with equations" />
      </Link>
      <div>
        <Link to="/about">
          <div className="bp3-text-large"> about </div>
        </Link>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="home-page-navbar">
      <div>
         <div className="bp3-text-large"> contact us </div>
      </div>
      <div className="mg-y-small">
        <ShareButtons
          title=""
          size="3em"
          twitter="https://twitter.com/fb11challenge"
          instagram="https://www.instagram.com/football11challenge/"
          facebook="" />
      </div>
    </div>
  );
}

function About() {
  return (
    <div className="about">
      <h1> About </h1>
      <Divider />
      <div>
        <p className="bp3-running-text">
          It all started with this
            <a href="https://twitter.com/Carra23/status/1250066001821130759"> tweet </a>
            by Jamie Carragher.  The premise is simple: <b>pick a starting 11 in which
          no two players can be from the same country or have played for the same
            club.</b>
        </p>

        <p className="bp3-running-text">
          A friend and I spent the better part of an hour coming up with our own
          starting 11.  After careful deliberation we arrived at our final squad
            ...only to discover that <b> Kevin de Bruyne played for Chelsea at some
          point. Consequently we had to wish John Terry a fond farewell from our
            squad.</b>
        </p>

        <p className="bp3-running-text">
          The entire process was extremely entertaining but I was left disaffected
          by the manual verification process. We had to think of a player then
          run over to Wikipedia to peruse the list of clubs that they played for.
            <b> If only there was a way to automatically check if a squad satisfied
            the constraints of the challenge?</b>
        </p>

        <p>
          Then it dawned on me that <b>it
          would be fun to create arbitary constraints and share these
            challenges with my friends </b>. And thus <b>footy11challenge.com </b>
             was born.
          </p>
      </div>

      <h2> FAQs </h2>

      <p className="bp3-text-large">
        <b> Where is the player data from?</b>
      </p>
      <p>
        Player data is downloaded from Wikipedia. As such it may be incomplete or incorrect.
        </p>
    </div>
  );
}

function CreateChallengeButton() {
  return (
    <Link to="/challenge/create">
      <Button
        text="Create a challenge"
        rightIcon="arrow-right"
        minimal
        outlined
        fill
        large />
    </Link>
  );
}

function SampleChallengeCarousel() {
  return (

    <div>
      <h1> A few challenges to get you started: </h1>
        <Carousel>
          <Link to='/challenge/eb483008-b211-4f2e-910a-7ba1fe9cf239'>
            <DisabledChallengeEditor challengeId='eb483008-b211-4f2e-910a-7ba1fe9cf239' />
          </Link>
          <Link to='/challenge/ade87b78-8893-4acd-a1a0-021862b8efbe'>
            <DisabledChallengeEditor challengeId='ade87b78-8893-4acd-a1a0-021862b8efbe' />
          </Link>
          <Dots />
         </Carousel>
    </div>
  );
}

function Steps() {
  return (
    <div className="steps">
      <h1>
        Create interesting challenges to test your knowledge of world football!
      </h1>
      <div className="column button">
        <CreateChallengeButton />
      </div>

      <Divider />
      <div className="column">
        <div className="row">
          <div className="number">
            <div>  1 </div>
          </div>
          <h3> Create rules by including or excluding specific countries, clubs, or players </h3>
        </div>
        <div className="center">
          <img src={createRules} alt="Screenshot of rules that users can define" />
        </div>
      </div>
      <Divider />

      <div className="column">
        <div className="row">
          <div className="number">
            <div>  2 </div>
          </div>
          <h3> Share your challenge with friends </h3>
        </div>
        <div className="center">
          <img src={shareYourChallenge}
            alt="A share dialog showing a direct link to the challenge, twitter, and facebook." />
        </div>
      </div>
      <Divider />

      <div className="column">
        <div className="row">
          <div className="number">
            <div>  3 </div>
          </div>
          <h3>
            Solve the challenge by choosing a starting 11 that satisfies all the rules.
            Drag the players around to create any formation.
           </h3>
        </div>
        <div className="center">
          <img src={shareYourSquad} alt="Screenshot of rules that users can define" />
        </div>
      </div>
      <Divider />

       <div className="column">
        <div className="row">
          <div className="number">
            <div>  4 </div>
          </div>
          <h3>
            Fix any errors in your squad
           </h3>
        </div>
        <div className="center">
          <img src={fixErrors} alt="Screenshot of errors that the squad violates" />
        </div>
      </div>
      <Divider />

      <div className="column">
        <div className="row">
          <div className="number">
            <div>  5 </div>
          </div>
          <h3> Argue about whose squad is better </h3>
        </div>
      </div>

      <Divider />

      <div className="column button">
        <CreateChallengeButton />
      </div>

      <SampleChallengeCarousel />

    </div>
  );
}

function App() {
  const location = useLocation();

  // Log the route change for Google Analytics.
  React.useEffect(() => {
    gtag('config', 'G-5R9RBP0PJ6', {'page_path': location.pathname});

  }, [location]);

  return (

      <div className="App">
        <HomePageNavbar />
        <Switch>
          <Route path='/' exact>
            <Steps />
          </Route>

          <Route path='/about' exact>
            <About />
          </Route>

          <Route path="/challenge/create" exact>
            <ChallengeEditor
              challenge={Challenge.empty()}
              disabled={false} />
          </Route>

          <Route path="/challenge/:challengeId">
            <SolutionEditor />
          </Route>

          <Route path='/squad/:squadId'>
            <SolutionDetail />
          </Route>
        </Switch>
        <Footer/>
      </div>
  );
}

function AppWithRouter() {
  return (
     <BrowserRouter>
        <React.Suspense fallback={<Spinner size={SpinnerSize.LARGE} />}>
          <App/>
       </React.Suspense>
     </BrowserRouter>
   );
}

export default AppWithRouter;
