export interface NationalTeam {
    readonly id: string;
    readonly name: string;
}

export interface ClubTeam {
    readonly id: string;
    readonly name: string;
}

export interface Player {
    readonly id: string;
    readonly name: string;
    readonly currentClub?: number;
    readonly country: NationalTeam;
    readonly teams: PlayerTeamAssociation[];
}

export interface PlayerTeamAssociation {
    readonly team: ClubTeam;
    readonly start: number;
    readonly end: number;
}

export interface Constraint {
    /** The ID of the Constraint. */
    id?: number;
    /** The ID of the Challenge that this constraint corresponds to. */
    challenge?: number;
    /** Whether the referenced entity(country, team or player) should be excluded. */
    exclude: boolean;
    /** The player that this constraint applies to. */
    country?: NationalTeam;
    /** The club team that this constraint applies to. */
    team?: ClubTeam;
    /** The  player that this constraint applies to. */
    player?: Player;
}

export interface PlayerChallengeSolutionAssociation {
    index: number;
    solution?: number;
    player: Player;
    x: number,
    y: number
}

export interface ChallengeSolution {
    id?: string;
    username: string;
    challenge: string;
    image?: string;
    squad: PlayerChallengeSolutionAssociation[];
}

export interface ConstraintValidator {
    validate(players: Array<Player>): boolean;

}

export class Challenge {
    id?: string;
    username: string;
    description: string;
    validateWithCurrentTeam: boolean;
    maxPlayersPerCountry: number;
    maxPlayersPerClub: number;
    nationalTeamConstraints: Constraint[];
    excludeNationalTeams: boolean
    clubTeamConstraints: Constraint[];
    excludeClubTeams: boolean;
    playerConstraints: Constraint[];
    excludePlayers: boolean;

    constructor(
        description: string,
        username: string,
        validateWithCurrentTeam: boolean,
        maxPlayersPerClub: number,
        maxPlayersPerCountry: number,
        constraints: Constraint[], id?: string) {
        this.id = id;
        this.username = username;
        this.description = description;
        this.validateWithCurrentTeam = validateWithCurrentTeam;
        this.maxPlayersPerClub = maxPlayersPerClub;
        this.maxPlayersPerCountry = maxPlayersPerCountry;
        this.nationalTeamConstraints = constraints.filter((c) => !!c.country);
        this.excludeNationalTeams = this.nationalTeamConstraints.some((c) => c.exclude);
        this.clubTeamConstraints = constraints.filter((c) => !!c.team);
        this.excludeClubTeams = this.clubTeamConstraints.some((c) => c.exclude);
        this.playerConstraints = constraints.filter((c) => !!c.player);
        this.excludePlayers = this.playerConstraints.some((c) => c.exclude);
    }

    public static empty(): Challenge {
        return new Challenge('', '', false, 11, 11, []);
    }

    public hasNationalTeamConstraints(): boolean {
        return this.nationalTeamConstraints.length !== 0;
    }

    public hasClubTeamConstriants(): boolean {
        return this.clubTeamConstraints.length !== 0;
    }

    public hasPlayerConstraints(): boolean {
        return this.playerConstraints.length !== 0;
    }

    public nationalTeams(): NationalTeam[] {
        return this.nationalTeamConstraints.map((c) => c.country as NationalTeam);
    }

    public clubTeams(): ClubTeam[] {
        return this.clubTeamConstraints.map((c) => c.team as ClubTeam);
    }

    public players(): Player[] {
        return this.playerConstraints.map((c) => c.player as Player);
    }
}


export interface ConstraintViolation {
    player: Player;
    constraint: Constraint;
}

export interface MaxPlayersPerCountryViolation {
    country: NationalTeam;
    players: Player[];
}

export interface MaxPlayersPerClubViolation {
    club: ClubTeam;
    players: Player[];
}
